import React, { createRef } from 'react'
import { Box, Container, Divider, Link, List, Typography } from '@mui/material'
import Layout from '../components/layout'
import { graphql } from 'gatsby'
import { useSiteMetadata } from '../hooks/use-site-metadata'
import BlogListItem from '../components/blog-list-item'

const TagPage = ({ data }: any) => {
    const {
        allBlogJson: { edges: allBlogEdges },
        allBlogTag: { distinct: allBlogTagDistinct },
    } = data

    const {
        site: {
            siteMetadata: { siteUrl, siteTitle },
        },
    } = useSiteMetadata()

    const blogs = allBlogEdges.map((item: any) => {
        return {
            ...item.node,
        }
    })

    let tags: string[] = []
    allBlogTagDistinct.forEach((tag: any) => {
        tags = [...tags, ...tag.split(',')]
    })
    tags = Array.from(new Set(tags)).sort()

    const tagBlogs = tags.map((tag: any) => {
        const ref = createRef()
        return { tag, ref, blogs: blogs.filter((blog: any) => blog.tags.split(',').includes(tag)) }
    })

    const title = `タグ | ${siteTitle}`
    const description = `タグ`
    return (
        <Layout title={title} description={description}>
            <Container sx={{ pt: { xs: 3, sm: 6 }, maxWidth: { xs: '100%', sm: '90%' } }}>
                <Typography variant="h1" sx={{ mb: 2, fontWeight: 'bold' }}>
                    タグ
                </Typography>
                <Box sx={{ wordBreak: 'break-word' }}>
                    {tagBlogs.map((tagBlog: any, index: number) => (
                        <Link
                            key={index}
                            onClick={() => {
                                window.scrollTo(0, tagBlog.ref.current.getBoundingClientRect().y)
                            }}
                            color="text.primary"
                            underline="none"
                            sx={{ display: 'inline-block', mx: 1, my: 0.5 }}
                        >
                            {tagBlog.tag}({tagBlog.blogs.length})
                        </Link>
                    ))}
                </Box>
                {tagBlogs.map((tagBlog, index: number) => (
                    <Box key={index} ref={tagBlog.ref}>
                        <Typography
                            variant="h2"
                            sx={{ mt: 4, fontWeight: 'bold' }}
                        >
                            {tagBlog.tag}
                        </Typography>
                        <List>
                            {tagBlog.blogs.map((blog: any, index: number) => {
                                return (
                                    <Box key={index}>
                                        {!!index && <Divider />}
                                        <BlogListItem blog={blog} />
                                    </Box>
                                )
                            })}
                        </List>
                    </Box>
                ))}
            </Container>
        </Layout>
    )
}

export const query = graphql`
    query TagPageQuery {
        allBlogJson(sort: { order: ASC, fields: category }) {
            edges {
                node {
                    id
                    blog_id
                    site
                    blog_url
                    feed_url
                    category
                    tags
                    image {
                        childImageSharp {
                            gatsbyImageData(
                                layout: FIXED
                                width: 96
                                height: 54
                                transformOptions: { cropFocus: CENTER }
                                quality: 90
                            )
                        }
                    }
                }
            }
        }
        allBlogTag: allBlogJson(sort: { fields: tags }) {
            distinct(field: tags)
        }
    }
`

export default TagPage
